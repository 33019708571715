import Link from "next/link";
import React from "react";

import path from "@/server/path";
import styles from "./Topic.module.scss";

export interface TopicProps {
  topic: string;
  size?: "sm" | "md" | "lg";
}

export default function Topic(props: TopicProps) {
  const sizeCss = props.size ?? "md";

  // TODO: remove `TOPIC` placeholder
  return (
    <div className={`flex flex-col ${styles[sizeCss]} ` + styles.container}>
      <Link passHref href={path.search(props.topic)}>
        <a className="hover:underline">
          <span className={styles.hash}>#</span>
          {props.topic || "<MISSING>"}
        </a>
      </Link>
    </div>
  );
}

import React, { FunctionComponent as FC } from "react";
import humanize from "humanize-plus";
import styles from "./TopicCounter.module.scss";
import { spawn } from "child_process";

interface TopicCounterProps {
  children?: React.ReactElement;
  topic: string;
  count: number;
}

const TopicCounter: FC<TopicCounterProps> = (props: TopicCounterProps) => {
  return (
    <>
      <span className="font-semibold text-lg">
        {humanize.compactInteger(props.count)}
      </span>
    </>
  );
};

export default TopicCounter;

import { useEffect, useState } from "react";

import { useI18n } from "@/i18n/hook";
import { TIME_FILTER_INTERVALS } from "@/model/timeFilter";
import { onStoreUpdate, trigger } from "@/store/store";
import type {
  FilterToolbar as FilterToolbarTy,
  PersonaFilter,
  TimeFilter,
} from "@/store/ty";
import DropdownFilter from "./DropdownFilter";

import { PersonaItem } from "@/lib/personaUtil";
import { personaFilterToQueryParam } from "@/model/personaFilter";
import { restoreFilterStateFromQueryParams } from "@/store/helper";
import { useRouter } from "next/router";
import Persona from "./Persona";
import { FilterProps } from "./util/filterToolbarTy";

const ALL = "__all__";

interface FilterToolbarProps extends FilterProps {
  /** hideTimeFilter whether to show the time filter */
  hideTimeFilter?: boolean;
  /** hidePersonaFilter whether to show the persona filter */
  hidePersonaFilter?: boolean;
  /** hideRegionFilter whether to show the region filter */
  hideRegionFilter?: boolean;
}

export default function FilterToolbar(props: FilterToolbarProps) {
  const router = useRouter();
  const [fbState, setFbState] = useState<FilterToolbarTy>(
    restoreFilterStateFromQueryParams(router.query)
  );

  // handles url query param update
  useEffect(() => {
    const s = onStoreUpdate("$.ui.filterToolbar", (res) => {
      const state = res.data[0] as FilterToolbarTy;
      setFbState(state);

      if (typeof window !== "undefined") {
        // const params = parse(window.location.search.substring(1));
        // params.rAfter = state?.timeFilter?.selectedInterval || undefined;
        // params.persona = personaFilterToQueryParam(state?.personaFilter ?? {});
        const url = new URL(window.location.toString());

        url.searchParams.set(
          "rAfter",
          state?.timeFilter?.selectedInterval || ""
        );
        url.searchParams.set(
          "persona",
          personaFilterToQueryParam(state?.personaFilter ?? {})
        );

        router.replace(url.toString(), undefined, {
          shallow: true,
        });
      }

      return () => {
        s.end(true);
      };
    });
  }, []);

  // on small screen, we show modal for filtering
  // instead of usual dropdown
  const shouldShowDropdown =
    typeof window !== "undefined" && window.screen.width >= 960;
  const onDropDownOnOpen = (key: keyof FilterToolbarTy) => () => {
    if (shouldShowDropdown) {
      return true;
    }
    // instead, we trigger the corresponding modal
    trigger.ui.filterToolbar({
      [key]: { modalVisible: true },
    });
    return false;
  };

  // TODO
  // const regionFilters = [];

  return (
    <div className="flex justify-between items-center">
      <ul className="text-gray-500 flex gap-5">
        {props.hideTimeFilter ? (
          <></>
        ) : (
          <li>
            <TimeFilterToolbar timeFilter={fbState?.timeFilter} />
          </li>
        )}
        {props.hidePersonaFilter ? (
          <></>
        ) : (
          <li>
            <PersonaFilterToolbar
              personaFilter={fbState?.personaFilter}
              staticItems={props.personaFilterStaticItems}
            />
          </li>
        )}
        <li hidden>
          <DropdownFilter<string>
            isLoading
            onOpen={onDropDownOnOpen("geoFilter")}
          >
            <div className="flex items-center">
              <span className="pl-1 md:pl-3">all regions</span>
              <i className="material-icons-outlined">expand_more</i>
            </div>
          </DropdownFilter>
        </li>
      </ul>
    </div>
  );
}

function TimeFilterToolbar({ timeFilter: tf }: { timeFilter?: TimeFilter }) {
  const i18n = useI18n();
  const timeFilterItems = TIME_FILTER_INTERVALS.map((interval) =>
    i18n.t(`timeFilter/${interval}`)
  );
  const selectedTimeFilterItem = i18n.t(
    "timeFilter/" + (tf?.selectedInterval || TIME_FILTER_INTERVALS[0])
  );

  return (
    <DropdownFilter<string>
      hideSearchbar
      highlight={
        Boolean(tf?.selectedInterval) &&
        tf?.selectedInterval !== TIME_FILTER_INTERVALS[0]
      }
      onSelect={(interval) =>
        trigger.ui.filterToolbar({
          timeFilter: { selectedInterval: interval },
        })
      }
      onOpen={onDropDownOnOpen("timeFilter")}
      displayItems={timeFilterItems}
      items={TIME_FILTER_INTERVALS}
    >
      <div className="text-sm md:text-base tracking-tighter flex items-center pb-2 border-b border-gray-300">
        <i className={"text-base md:text-lg material-icons-outlined"}>
          schedule
        </i>
        <span className="font-semibold pl-1 md:pl-3">
          {selectedTimeFilterItem}
        </span>
        <i className="material-icons-outlined">expand_more</i>
      </div>
    </DropdownFilter>
  );
}

function PersonaFilterToolbar({
  personaFilter: pf,
  staticItems,
}: {
  personaFilter?: PersonaFilter;
  staticItems: PersonaItem[];
}) {
  const i18n = useI18n();

  function personaFilterDisplay(i18n: any, pf?: PersonaFilter): string {
    const isVerified = pf?.verifiedOnly;
    if (isVerified) {
      return i18n.t("personaFilter/verified");
    }

    // TODO: we only support whitelisting filter
    const selected = Object.keys(pf?.filter ?? {});
    if (selected.length > 1) {
      return i18n.t("personaFilter/selectedMany");
    } else if (selected.length === 1) {
      return i18n.t("personaFilter/selectedOnly", { persona: selected[0] });
    }
    return i18n.t("personaFilter/all");
  }

  const items = [
    {
      persona: ALL,
    },
  ].concat(staticItems);

  const displayItems = items.map((item) => {
    if (item.persona === ALL) {
      return i18n.t("personaFilter/all");
    }
    return item.persona;
  });

  const highlight =
    Object.keys(pf?.filter ?? {}).length > 0 || pf?.verifiedOnly;

  return (
    <DropdownFilter<PersonaItem>
      highlight={highlight}
      onOpen={onDropDownOnOpen("personaFilter")}
      renderItem={(item: PersonaItem) => {
        if (item.persona === ALL) {
          return (
            <span className="font-bold">{i18n.t("personaFilter/all")}</span>
          );
        }
        return (
          <Persona
            key={item.persona}
            noLink
            persona={item.persona}
            count={(item as any).count!}
          />
        );
      }}
      onSelect={(item: PersonaItem) => {
        if (item.persona === ALL) {
          trigger.ui.filterToolbar({
            personaFilter: {
              filter: {},
            },
          });
        } else {
          trigger.ui.filterToolbar({
            personaFilter: {
              filter: {
                [item.persona]: true,
              },
            },
          });
        }
      }}
      displayItems={displayItems}
      items={items}
    >
      <div className="text-sm md:text-base tracking-tighter flex items-center pb-2 border-b border-gray-300">
        <i className={"text-base md:text-lg material-icons-outlined"}>groups</i>
        <span className="font-semibold pl-1 md:pl-3">
          {personaFilterDisplay(i18n, pf)}
        </span>
        <i className="material-icons-outlined">expand_more</i>
      </div>
    </DropdownFilter>
  );
}

const shouldShowDropdown =
  typeof window !== "undefined" && window.screen.width >= 960;
const onDropDownOnOpen = (key: keyof FilterToolbarTy) => () => {
  if (shouldShowDropdown) {
    return true;
  }
  // instead, we trigger the corresponding modal
  trigger.ui.filterToolbar({
    [key]: { modalVisible: true },
  });
  return false;
};

import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

import styles from "./Searchbar.module.scss";
import path from "@/server/path";
import { useI18n } from "@/i18n/hook";
import { trigger } from "@/store/store";
import Logo from "./Logo";

interface SearchbarProps {}

export default function Searchbar(props: SearchbarProps) {
  const i18n = useI18n();
  const router = useRouter();

  // TODO: race condition between router readiness and this component from rendering
  const [val, setVal] = useState("");

  useEffect(() => {
    setVal(router.query.q as string);
  }, [router.isReady]);

  const handleSearch = () => {
    if (val === undefined || val === "") {
      trigger.ui.notification.warn(i18n.t("n10n_empty_search"));
    } else {
      router.push(path.search(val));
    }
  };

  return (
    <>
      <div
        className={`w-full min-w-full m-auto shadow-sm flex items-center justify-around rounded-lg md:rounded-full ${styles.container} focus-within:border-blue-400 border-2 border-gray-200`}
      >
        <input
          className="border border-blue-400"
          type="text"
          placeholder={i18n.t("searchbar_search_by_topic")}
          value={val}
          onChange={(e) => setVal((e.target as any).value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <span className={styles.divider}></span>
        <button
          className={`lg:ml-1 border-l border-gray-300 flex items-center pointer ${styles.btn}`}
          onClick={() => {
            handleSearch();
          }}
        >
          <i className="m-auto material-icons-outlined">search</i>
        </button>
      </div>
    </>
  );
}

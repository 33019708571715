import api from "@/api";
import { Result } from "@/api/ty";
import fetcher from "@/fetcher";
import { AggItem } from "@/gen/agg";
import { useI18n } from "@/i18n/hook";
import { TopResult } from "@/model/top";
import { onStoreUpdate } from "@/store/store";
import { FilterToolbar, PersonaFilter, TimeFilter } from "@/store/ty";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR from "swr";
import Loading from "./Loading";
import Persona from "./Persona";
import TitleWithInfo from "./TitleWithInfo";
import Topic from "./Topic";
import TopicCounter from "./TopicCounter";

interface RealtimeTrendProps {
  by: "personasByTopic" | "topicsByPersona";
  showTitle?: boolean;
}

type TopPersonasByTopicItem = AggItem & { topics: TopResult };
type TopTopicsByPersonaItem = AggItem & { personas: TopResult };

export default function RealtimeTrend(props: RealtimeTrendProps) {
  const [pf, setPf] = useState<PersonaFilter>({});
  const [tf, setTf] = useState<TimeFilter>({});
  const i18n = useI18n();

  const { data } = useSWR<Result>(
    api.top[props.by]({
      personaFilter: pf,
      timeFilter: tf,
    }),
    fetcher
  );

  useEffect(() => {
    const s = onStoreUpdate("$.ui.filterToolbar", (res) => {
      const state = res.data[0] as FilterToolbar;
      setPf(state?.personaFilter ?? {});
      setTf(state?.timeFilter ?? {});
    });
    return () => {
      s.end(true);
    };
  }, []);

  if (!data) {
    return <Loading textOnly />;
  }

  const i18nTitle =
    props.by === "personasByTopic"
      ? i18n.t("trending_personasByTopic")
      : i18n.t("trending_topicsByPersona");

  const title = props.showTitle ? <TitleWithInfo title={i18nTitle} /> : <></>;

  let renderedItems: JSX.Element[];
  if (props.by === "personasByTopic") {
    const { items } = data.result as { items: TopPersonasByTopicItem[] };
    renderedItems = items.map((i, key: number) => (
      <div key={key} className="px-5 py-3 md:px-10 md:p-5">
        <PersonaTopicRenderer i18n={i18n} item={i} />
      </div>
    ));
  } else {
    const { items } = data.result as { items: TopTopicsByPersonaItem[] };
    renderedItems = items.map((i, key: number) => (
      <div key={key} className="px-5 py-3 md:px-10 md:p-5">
        <PersonasByTopicRenderer i18n={i18n} item={i} />
      </div>
    ));
  }

  return (
    <div className="md:py-0">
      <div className="mb-3">{title}</div>
      <div className="md:rounded-lg shadow border border-black-100">
        <div className="divide-y divide-black-100">
          <ul className="bg-white rounded divide-y divide-black-100">
            {renderedItems}
          </ul>
        </div>
      </div>
    </div>
  );
}

/**
 *
 * Renders the top topics under a given persona
 * @param props
 * @returns
 */
function PersonaTopicRenderer(props: {
  item: TopPersonasByTopicItem;
  i18n: ReturnType<typeof useI18n>;
}) {
  const { i18n, item } = props;
  const topics = item.topics?.items?.map((i, key: number) => (
    <Topic key={key} topic={i.topic!} />
  ));

  return (
    <li className="flex flex-col gap-2">
      <div className="flex gap-1 items-center">
        <Persona
          persona={item.persona!}
          count={item.count!}
          hideCountry={true}
        />
        <span className="text-slate-400">{i18n.t("is_chatting")}</span>
      </div>
      <div className="flex flex-wrap gap-y-2 gap-x-3">{topics}</div>
    </li>
  );
}

/**
 * Renders the top personas under a given topic
 * @param props
 * @returns
 */
function PersonasByTopicRenderer(props: {
  item: TopTopicsByPersonaItem;
  i18n: ReturnType<typeof useI18n>;
}) {
  const router = useRouter();
  const { locale } = router;

  const { i18n, item } = props;
  const personas = item.personas.items.map((i) => (
    <Persona hideCounter hideCountry persona={i.persona!} count={i.count!} />
  ));
  const totalCount = item.personas.items.reduce((acc: number, i) => {
    return acc + parseInt(i.count!, 10);
  }, 0);

  return (
    <li className="flex flex-col gap-2">
      <div className="flex flex-wrap gap-2 items-center">
        <TopicCounter topic={item.topic!} count={totalCount} />
        <span className="text-slate-400 block">
          {i18n.t("people") + i18n.t("is_chatting")}
        </span>
        <Topic topic={item.topic!} />
        <span className="text-gray-400">{i18n.t("chatting_including")}</span>
      </div>
      <div className="flex flex-wrap gap-y-2 gap-x-3">{personas}</div>
    </li>
  );
}

import React, { FunctionComponent as FC } from "react";
import styles from "./TitleWithInfo.module.scss";

interface TitleWithInfoProps {
  title: string;
  showInfo?: boolean;
}

const TitleWithInfo: FC<TitleWithInfoProps> = (props: TitleWithInfoProps) => {
  return (
    <div className="flex items-center justify-between mb-1">
      <div className="flex items-center gap-2">
        <h3 className="text-2xl">{props.title}</h3>
        <i className={"text-gray-500 material-icons-outlined text-3xl"}>
          trending_up
        </i>
      </div>
      {/* TODO
      <button className="rounded bg-white p-2 ">
        <i className={"material-icons-outlined "}>info</i>
      </button> */}
    </div>
  );
};

export default TitleWithInfo;

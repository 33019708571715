import useBlurClick from "@/hook/useBlurClick";
import { useI18n } from "@/i18n/hook";
import React, { ReactElement, useRef, useState } from "react";
import FilterSearchbar from "./FilterSearchbar";
import Loading from "./Loading";

interface DropdownFilterProps<T> {
  prompt?: string;
  showClearAll?: boolean;
  children: React.ReactElement | Array<React.ReactElement>;
  hideSearchbar?: boolean;
  isLoading?: boolean;
  highlight?: boolean;

  displayItems?: string[];
  items?: T[];
  onOpen?: () => boolean;
  selected?: Set<string>;

  renderItem?: (item: T) => React.ReactElement | Array<ReactElement>;
  filterItem?: (searchTerm: string, item: T) => boolean;
  onSelect?: (item: T) => void;
}

function DropdownFilter<T>(props: DropdownFilterProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<Set<string>>(
    props.selected ?? new Set()
  );
  const [filtered, setFiltered] = useState<Array<[T, string]> | null>(null);
  const i18n = useI18n();

  const selectionRef = useRef(null);
  const dropdownRef = useRef(null);
  const uiRef = useRef(null);

  const isLoading = false;

  const items: T[] = props.items || [];
  const displayItems: string[] =
    props.displayItems ?? items.map((item) => (item as any).toString());
  const itemTuple: Array<[T, string]> = items.map((item, i) => {
    return [item, displayItems[i]];
  });

  function toggleDropdown() {
    if (!isOpen && props.onOpen) {
      if (props.onOpen()) {
        setIsOpen(!isOpen);
      }
    } else {
      setIsOpen(!isOpen);
    }
  }

  function onFilterUpdate(prefix: string) {
    if (!prefix) {
      setFiltered(itemTuple);
    } else {
      const filterFn = (itemTuple: [T, string]) =>
        typeof props.filterItem === "function"
          ? props.filterItem(prefix, itemTuple[0])
          : itemTuple[1].startsWith(prefix);

      setFiltered(itemTuple.filter(filterFn));
    }
  }

  function onSelect(item: T) {
    // selected.add(item);
    // setSelected(item);
    props.onSelect && props.onSelect(item);
    setIsOpen(false);
  }

  useBlurClick([uiRef, selectionRef, dropdownRef], () => setIsOpen(false));

  //   props.onSelect && props.onSelect(defaultPersona);
  return (
    <div className="flex flex-col">
      <a
        ref={uiRef}
        onClick={toggleDropdown}
        className={`pointer md:p-2 rounded-lg md:hover:bg-gray-100 ${
          props.highlight ? "font-bold text-blue-400" : ""
        }`}
      >
        {props.children}
      </a>
      <div ref={dropdownRef} className="relative z-10">
        {!isOpen ? (
          <></>
        ) : (
          <div className="p-2 mt-2 rounded-lg absolute border border-1 border-gray-200 bg-white w-max left-0 shadow-sm text-black">
            {props.hideSearchbar ? (
              <></>
            ) : (
              <div className="mb-2">
                <FilterSearchbar
                  placeholder={props.prompt}
                  onUpdate={onFilterUpdate}
                />
              </div>
            )}
            {props.isLoading ? (
              <Loading />
            ) : (
              <ul className="max-h-64 overflow-y-auto no-scrollbar">
                {(filtered ?? itemTuple).map(
                  (itemTuple: [T, string], idx: number) => (
                    <li
                      key={idx}
                      className="hover:bg-gray-100 cursor-pointer flex flex-align-center flex-space-between p-2"
                    >
                      <button
                        className="text-left w-full"
                        onClick={() => onSelect(itemTuple[0])}
                      >
                        {typeof props.renderItem === "function" ? (
                          props.renderItem(itemTuple[0])
                        ) : (
                          <span>{itemTuple[1]}</span>
                        )}
                      </button>
                    </li>
                  )
                )}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DropdownFilter;
